import React, { useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Importa il plugin per le etichette dei dati
import { getLabelFromPatientStatus, titleCase } from "../utils/common";
import { labels, psTranslate } from "./shared/translations";

import moment from "moment";
// Registra i componenti di Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);
import { Divider, Grid, Typography } from "@material-ui/core";
import TreatmentHelper from "./ProfessionalReportGeneratorHelper";

const ProfessionalReportGenerator = ({
  reportData,
  profTreatment,
  handleClick,
  setHandleClick,
  setLoading,
  logoImg,
  start_date,
  end_date,
}) => {
  useEffect(() => {
    if (handleClick) {
      generatePDF();
      setHandleClick(false);
    }
  }, [handleClick]);

  const generatePDF = () => {
    setLoading(true);
    const doc = new jsPDF("landscape");
    doc.setFontSize(12);

    const addHeader = (doc) => {
      const pageWidth = doc.internal.pageSize.getWidth() - 50;

      // Barra colorata
      const barHeight = 1; // Altezza della barra
      const barWidth = pageWidth; // Larghezza della barra
      doc.setDrawColor(73, 197, 182); // Colore RGB della barra
      doc.setFillColor(73, 197, 182);
      doc.rect(0, 201, barWidth, barHeight, "F"); // Disegna la barra (F = fill)

      // Testo sotto la barra
      doc.setFont("helvetica", "italic");
      doc.setFontSize(9);
      doc.setTextColor(73, 197, 182);
      const textFooter = `${moment(start_date).format("DD/MM/YYYY")} - ${moment(
        end_date
      ).format("DD/MM/YYYY")}`;
      const textWidth =
        (doc.getStringUnitWidth(textFooter) * doc.internal.getFontSize()) /
        doc.internal.scaleFactor;
      const textX = (doc.internal.pageSize.getWidth() - textWidth) / 2; // Centra il testo orizzontalmente
      doc.text(textFooter, textX, 207);
    };

    const addImage = () => {
      const imgWidth = 19;
      const imgHeight = (logoImg.height / logoImg.width) * imgWidth;
      doc.addImage(logoImg, "PNG", 260, 200, imgWidth, imgHeight);
      addHeader(doc);
    };

    const imgWidth = 190;
    const imgHeight = (logoImg.height / logoImg.width) * imgWidth;
    doc.addImage(logoImg, "PNG", 50, 90, imgWidth, imgHeight);
    doc.addPage();
    addImage();

    // Grafico delle attività
    const activityDurationProfessional = document.getElementById(
      "activityDurationProfessional"
    );
    if (activityDurationProfessional) {
      const activityImage = activityDurationProfessional.toDataURL("image/png");
      doc.setFont("helvetica", "normal");
      doc.setFontSize(12);
      doc.text(labels.analytics.account.activityFreq, 10, 10);
      doc.addImage(activityImage, "PNG", 10, 20, 180, 90);
    }

    Object.entries(profTreatment)?.map(([professional, activities]) => {
      doc.addPage();
      addImage();

      const activityTreatmentProfessional = document.getElementById(
        `activityTreatmentProfessional${professional}`
      );
      if (activityTreatmentProfessional) {
        const activityImage =
          activityTreatmentProfessional.toDataURL("image/png");

        // Ottieni le dimensioni della pagina PDF
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        // Dimensioni del grafico originale
        const chartWidth = 1000; // Come specificato nel componente React
        const chartHeight = 500;

        // Calcola le proporzioni per adattare il grafico alla pagina
        const aspectRatio = chartWidth / chartHeight;
        let imageWidth = pageWidth - 20; // Margine
        let imageHeight = imageWidth / aspectRatio;

        // Se l'altezza supera i limiti della pagina, ridimensiona
        if (imageHeight > pageHeight - 20) {
          imageHeight = pageHeight - 20;
          imageWidth = imageHeight * aspectRatio;
        }

        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        doc.text(professional, 10, 10);

        // Aggiungi il grafico
        doc.addImage(activityImage, "PNG", 10, 20, imageWidth, imageHeight);
      }
    });
    // Salvare il PDF
    setLoading(false);
    doc.save(`reportProfessional${moment().format("DD/MM/YYYY")}.pdf`);
  };

  // Colori per ogni tipo di attività
  const activityColors = {
    [psTranslate("Valutazione")]: "rgba(75, 192, 192, 0.6)",
    [psTranslate("Trattamento")]: "rgba(255, 99, 132, 0.6)",
    [psTranslate("Esercizio")]: "rgba(54, 162, 235, 0.6)",
    [psTranslate("Allenamento")]: "rgba(255, 206, 86, 0.6)",
    [psTranslate("Riposo")]: "rgba(153, 102, 255, 0.6)",
    [psTranslate("Altro")]: "rgba(255, 159, 64, 0.6)",
    [psTranslate("Gara")]: "rgba(100, 100, 100, 0.6)",
    [psTranslate("Riabilitazione")]: "rgba(200, 99, 132, 0.6)",
    [psTranslate("Supplemento")]: "rgba(75, 192, 192, 0.3)",
  };

  // Raggruppa i dati per creare dataset del grafico
  const generateChartData = () => {
    const athleteNames = reportData.map(
      (professional, index) =>
        titleCase(`${professional.name} ${professional.surname}`) ||
        `Atleta ${index + 1}`
    );

    const activityTypes = Object.keys(activityColors).map((item) =>
      psTranslate(item)
    );

    // Creare un dataset per ogni attività
    const datasets = activityTypes.map((activityType) => {
      const darkenedColor = "rgb(255, 255, 255)";

      return {
        label: activityType,
        data: reportData.map((professional) => {
          const activity = Object.keys(professional.activities).find(
            (a) => psTranslate(a) === activityType
          );
          return activity ? professional.activities[activity] : 0;
        }),
        backgroundColor: activityColors[activityType],
        datalabels: {
          color: darkenedColor,
          font: {
            weight: "bold",
            size: 12,
          },
          formatter: (value, context) => value,
        },
      };
    });

    return {
      labels: athleteNames,
      datasets,
    };
  };

  const chartData = generateChartData();

  const options = {
    indexAxis: "y", // Inverti l'asse da verticale a orizzontale
    plugins: {
      legend: { position: "top" },
      tooltip: { mode: "index", intersect: false },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: labels.patient.graphReport.filtersButton.activity,
        },
      },
      y: { stacked: true },
    },
  };

  return (
    <div>
      <h2>{labels.account.accountReports.activitiesProfessional}</h2>
      <Typography>
        {labels.account.accountReports.activitiesProfessionalDetail}
      </Typography>
      <Bar
        data={chartData}
        options={options}
        id="activityDurationProfessional"
      />
      {profTreatment && <ActivityDurationBarChart data={profTreatment} />}
    </div>
  );
};

const ActivityDurationBarChart = ({ data }) => {
  const charts = Object.entries(data)?.map(([professional, activities]) => {
    if (activities.length === 0) {
      return (
        <div key={professional}>
          <h2>{professional}</h2>
          <p>No data available</p>
        </div>
      );
    }

    // Aggiungi larghezza e altezza personalizzate
    return (
      <div key={professional} style={{ paddingTop: "5em" }}>
        <h2>{professional}</h2>
        <TreatmentHelper activities={activities} professional={professional} />
      </div>
    );
  });

  return <div>{charts}</div>;
};

export default ProfessionalReportGenerator;
