import { Grid } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import React from "react";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpText from "../../../components/atoms/SpText";
import { labels } from "../../shared/translations";
import { keysStatus } from "../../../utils/common";
import SpButton from "../../../components/atoms/SpButton";
import moment from "moment";
import SpDialog from "../../../components/atoms/SpDialog";
import { theme } from "../../../components/theme";
import { updatePatientDisponibility } from "../../../models/actions/Disponibility";

export const LabelValue = ({ label, value }) => (
  <Grid direction="column" style={{ marginBottom: "5px" }}>
    <SpText variant="text">{label}</SpText>
    <SpText variant="h4ComponentLabel">{value}</SpText>
  </Grid>
);

export const ImageWithBackgroundColor = ({ imageUrl, backgroundColor }) => {
  const containerStyle = {
    backgroundColor,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100",
    height: "100",
    borderRadius: "50%",
  };

  const imageStyle = {
    maxWidth: "2em",
    maxHeight: "2em",
    margin: "8px",
  };

  return (
    <div style={containerStyle}>
      <img src={imageUrl} alt="Immagine" style={imageStyle} />
    </div>
  );
};

export const ActivityFilter = ({ data, filterFunction }) => {
  const nRed = data.filter(
    ({ training_status }) => training_status !== keysStatus.fit
  )?.length;
  const nGreen = data.filter(
    ({ training_status }) => training_status === keysStatus.fit
  )?.length;
  return (
    <Grid item container direction="row" xs={12}>
      <Grid item container xs={12} direction="row">
        <LabelValue
          label={""}
          value={labels.groups.groupReports.firstTable.header.trainingStatus}
        />
      </Grid>

      {[
        {
          funct: () => filterFunction({ status: keysStatus.fit }),
          value: nGreen,
          color: "green",
          label: labels.groups.groupReports.firstTable.trainingStatus.find(
            ({ key }) => key === keysStatus.fit
          ),
        },
        {
          funct: () => filterFunction({ status: keysStatus.unavailable }),
          value: nRed,
          color: "red",
          label: labels.groups.groupReports.firstTable.trainingStatus.find(
            ({ key }) => key === keysStatus.unavailable
          ),
        },
      ].map(({ funct, value, color, label }) => {
        return (
          <Grid item container xs={4} direction="row">
            <Grid item xs={5}>
              <SpIconButton
                variant="lightBlue"
                style={{
                  marginLeft: 20,
                  cursor: "default",
                  height: "2em",
                  width: "2em",
                  marginBottom: "1em",
                }}
                onClick={funct}
              >
                <ImageWithBackgroundColor
                  imageUrl={require(`../../../assets/activities/training.png`)}
                  backgroundColor={color}
                />
              </SpIconButton>
            </Grid>
            <Grid item xs={7}>
              <LabelValue label={label?.label} value={value} />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export const HealthFilter = ({ data, filterFunction }) => {
  const nGreen = data.filter(({ status }) => status === keysStatus.fit)?.length;
  const nBlue = data.filter(
    ({ status }) => status === keysStatus.illness
  )?.length;
  const nRed = data.filter(
    ({ status }) => status === keysStatus.time_loss
  )?.length;
  const nYellow = data.filter(
    ({ status }) => status === keysStatus.any_complaint
  )?.length;
  const nOrange = data.filter(
    ({ status }) => status === keysStatus.medical_attention
  )?.length;
  return (
    <Grid item container direction="row" xs={12}>
      <Grid item container xs={12} direction="row">
        <LabelValue
          label={""}
          value={labels.groups.groupReports.firstTable.header.status}
        />
      </Grid>
      {[
        {
          func: () => filterFunction({ health: keysStatus.fit }),
          color: "green",
          value: nGreen,
          label: labels.patient.list.table.patientRow.status.healthy,
        },
        {
          func: () => filterFunction({ health: "illness" }),
          color: "blue",
          value: nBlue,
          label:
            labels.groups.groupReports.firstTable.trainingActivitiesUnavailable.find(
              ({ key }) => key === "illness"
            )?.label,
        },
        {
          func: () => filterFunction({ health: keysStatus.time_loss }),
          color: "red",
          value: nRed,
          label:
            labels.groups.groupReports.firstTable.trainingActivitiesUnavailable.find(
              ({ key }) => key === "time_loss"
            )?.label,
        },
        {
          func: () => filterFunction({ health: keysStatus.any_complaint }),
          color: "yellow",
          value: nYellow,
          label:
            labels.groups.groupReports.firstTable.trainingActivitiesUnavailable.find(
              ({ key }) => key === "any_complaint"
            )?.label,
        },
        {
          func: () => filterFunction({ health: keysStatus.medical_attention }),
          color: "orange",
          value: nOrange,
          label:
            labels.groups.groupReports.firstTable.trainingActivitiesUnavailable.find(
              ({ key }) => key === "medical_attention"
            )?.label,
        },
      ].map(({ func, color, value, label }) => (
        <Grid item container xs={3} direction="row">
          <Grid item xs={4}>
            <SpIconButton
              variant="lightBlue"
              style={{ marginLeft: 20, cursor: "default" }}
              onClick={func}
            >
              <FiberManualRecordIcon
                style={{
                  color: color,
                  fontSize: "2em",
                }}
              />
            </SpIconButton>
          </Grid>
          <Grid item xs={6}>
            <LabelValue label={label} value={value} />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export const SelectPeriod = ({
  setFilterStartDate,
  filterStartDate,
  setFilterEndDate,
  filterEndDate,
}) => (
  <Grid
    container
    xs={12}
    direction="row"
    style={{ marginBottom: "2%", justifyContent: "center" }}
  >
    <SpButton
      buttonType="accept"
      text={labels.patient.monitoring.table.prevTrimester}
      onClick={() => {
        setFilterStartDate(moment(filterStartDate).subtract(1, "months"));
        setFilterEndDate(moment(filterEndDate).subtract(1, "months"));
      }}
    />
    <SpText variant="h1PageSubtitle" style={{ alignSelf: "center" }}>
      {moment(filterStartDate).format("DD/MM/YYYY")} -{" "}
      {moment(filterEndDate).format("DD/MM/YYYY")}
    </SpText>
    <SpButton
      buttonType="accept"
      text={labels.patient.monitoring.table.nextTrimester}
      style={{ marginRight: "0", marginLeft: "3%" }}
      onClick={() => {
        setFilterStartDate(moment(filterStartDate).add(1, "months"));
        setFilterEndDate(moment(filterEndDate).add(1, "months"));
      }}
    />
  </Grid>
);

export const LegendStatus = () => {
  return (
    <Grid xs={6} container direction="row">
      <Grid
        xs={12}
        container
        direction="row"
        style={{ justifyContent: "flex-end" }}
      >
        <FiberManualRecordIcon style={{ cursor: "default", color: "green" }} />
        <SpText variant="h4ComponentLabel">
          {labels.patient.monitoring.table.in_good_health}
        </SpText>
        <FiberManualRecordIcon
          style={{ cursor: "default", color: "red", marginLeft: "3px" }}
        />
        <SpText variant="h4ComponentLabel">
          {
            labels.groups.groupReports.firstTable.trainingActivitiesUnavailable.find(
              ({ key }) => key === "time_loss"
            )?.label
          }
        </SpText>
        <FiberManualRecordIcon
          style={{ cursor: "default", color: "#31cc", marginLeft: "3px" }}
        />
        <SpText variant="h4ComponentLabel">
          {labels.patient.monitoring.table.in_illness}
        </SpText>
        <FiberManualRecordIcon
          style={{ cursor: "default", color: "orange", marginLeft: "3px" }}
        />
        <SpText variant="h4ComponentLabel">
          {
            labels.groups.groupReports.firstTable.trainingActivitiesUnavailable.find(
              ({ key }) => key === "medical_attention"
            )?.label
          }
        </SpText>
        <FiberManualRecordIcon
          style={{ cursor: "default", color: "yellow", marginLeft: "3px" }}
        />
        <SpText variant="h4ComponentLabel">
          {
            labels.groups.groupReports.firstTable.trainingActivitiesUnavailable.find(
              ({ key }) => key === "any_complaint"
            )?.label
          }
        </SpText>
      </Grid>
    </Grid>
  );
};
export const LegendAvailability = () => {
  return (
    <Grid xs={6} container direction="row">
      <Grid
        xs={12}
        container
        direction="row"
        style={{ justifyContent: "flex-end" }}
      >
        <FiberManualRecordIcon style={{ cursor: "default", color: "green" }} />
        <SpText variant="h4ComponentLabel">
          {
            labels.groups.groupReports.firstTable.trainingStatus.find(
              ({ key }) => key === "fit"
            )?.label
          }
        </SpText>
        <FiberManualRecordIcon
          style={{ cursor: "default", color: "red", marginLeft: "3px" }}
        />
        <SpText variant="h4ComponentLabel">
          {
            labels.groups.groupReports.firstTable.trainingStatus.find(
              ({ key }) => key === "unavailable"
            )?.label
          }
        </SpText>
      </Grid>
    </Grid>
  );
};

export const getActivityByTypeAndDate = (
  activityType,
  date,
  monitoringData
) => {
  return (
    monitoringData?.monitoring?.calendar_activities?.filter(
      (x) => x.keyActivityType === activityType && x.date === date
    )?.[0] ?? null
  );
};

export const getActivity = (activityType, date, monitoringData) =>
  getActivityByTypeAndDate(activityType, date, monitoringData)?.activity ??
  null;

export const activityExists = (activityType, date, monitoringData) =>
  getActivityByTypeAndDate(activityType, date, monitoringData);

export //function to manage the click on an event
const handleEventDetailSelected = ({
  activityData,
  minutesActivitiesCount,
  setSelectedActivity,
  setOpenDetailDialog,
  setOpenDetailActivity,
  openDetailActivity,
}) => {
  let newArray = openDetailActivity;
  minutesActivitiesCount.current = 0;
  const start = moment
    .utc(`${activityData.start_date} ${activityData.start_time}`)
    .local()
    .toDate();
  const end = moment
    .utc(`${activityData.start_date} ${activityData.end_time}`)
    .local()
    .toDate();

  const formattedSelectedObj = {
    id: activityData.id,
    id_activity_groups: activityData?.id_activity_groups,
    title: activityData?.activity_datum?.activity_type.name,
    description: "",
    start: start,
    end: end,
    start_time: activityData.start_time,
    end_time: activityData.end_time,
    type: activityData?.activity_datum?.name,
    event_note: activityData.event_note,
    id_presentation: activityData.id_presentation,
    id_activity_type: activityData?.activity_datum?.activity_type?.id,
    media: require(`../../../assets/activities/${activityData?.activity_datum?.activity_type?.key}.png`),
    data: activityData.activity_datum?.data,
    feedbacks: activityData.activity_feedbacks
      ? activityData.activity_feedbacks[0]?.activity_resp_parameter_feedbacks
        ? activityData.activity_feedbacks[0].activity_resp_parameter_feedbacks
        : []
      : [],
  };
  setSelectedActivity(formattedSelectedObj);
  setOpenDetailDialog(true);
  Object(newArray.find((elem) => elem.id == activityData.id)).value = !Object(
    newArray.find((elem) => elem.id == activityData.id)
  ).value;
  setOpenDetailActivity(newArray);
};

export const SingleCell = ({
  isSelectable,
  activityData,

  minutesActivitiesCount,
  setSelectedActivity,
  setOpenDetailDialog,
  setOpenDetailActivity,
  openDetailActivity,
}) => (
  <Grid
    item
    xs
    onClick={
      isSelectable
        ? () =>
            handleEventDetailSelected({
              activityData,
              minutesActivitiesCount,
              setSelectedActivity,
              setOpenDetailDialog,
              setOpenDetailActivity,
              openDetailActivity,
            })
        : null
    }
    style={{
      minHeight: 40,
      width: 80,
      border: "1px solid #31caad",
      padding: 4,
      backgroundColor: "transparent",
      textAlign: "center",
    }}
  >
    {isSelectable ? (
      <img
        src={require(`../../../assets/activities/${activityData?.activity_datum?.activity_type?.key}.png`)}
        style={{ maxWidth: 25, maxHeight: 25 }}
        alt={"img"}
      />
    ) : (
      ""
    )}
  </Grid>
);
export const activities = [
  "Assessment",
  "Trattamento",
  "Esercizio",
  "Allenamento",
  "Riposo",
  "Altro",
  "Gara",
  "Riabilitazione",
  "Supplementazione",
];

export const ChangeAvailabilityDialog = ({
  openToggleAvailability,
  setOpenToggleAvailability,
  availabilitySelected,
  updateEvent,
}) => {
  return (
    <SpDialog
      style={{ display: "flex", flex: 1 }}
      open={openToggleAvailability}
      setOpen={setOpenToggleAvailability}
      title={availabilitySelected?.patient}
    >
      <SpText>
        Date:{" "}
        {moment(availabilitySelected.date, "DD/MM/YYYY").format("DD/MM/YYYY")}
      </SpText>

      {
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <SpText variant="h4ComponentLabel">
              {labels.groups.groupReports.firstTable.changeAvailability}
              <span
                style={{
                  fontWeight: "bold",
                  color: theme.colors.primary.lightBlue,
                }}
              >
                {
                  labels.groups.groupReports.firstTable.trainingStatus.find(
                    ({ key }) => key === availabilitySelected.status
                  )?.label
                }
              </span>
              {labels.groups.groupReports.firstTable.changeAvailability1}

              <span
                style={{
                  fontWeight: "bold",
                  color: theme.colors.primary.lightBlue,
                }}
              >
                {
                  labels.groups.groupReports.firstTable.trainingStatus.find(
                    ({ key }) =>
                      key ===
                      (availabilitySelected.status === "fit"
                        ? "unavailable"
                        : "fit")
                  )?.label
                }
              </span>
              {" ?"}
            </SpText>
            <SpText style={{ paddingTop: "1em" }}>
              {labels.groups.groupReports.firstTable.changeAvailabilityNote}
            </SpText>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <SpButton
                id="deleteTrue"
                buttonType="cancel"
                text={labels.patient.presentation.detail.deleteConfirm}
                variant="h1PageTitle"
                onClick={async () => {
                  await updatePatientDisponibility({
                    id_patient: availabilitySelected.id_patient,
                    status:
                      availabilitySelected.status === "fit"
                        ? "unavailable"
                        : "fit",
                    date_selected: moment(
                      availabilitySelected.date,
                      "DD/MM/YYYY"
                    ).format("YYYY-MM-DD"),
                    id_activity_groups:
                      availabilitySelected?.id_activity_groups,
                  });
                  setOpenToggleAvailability(false);
                  await updateEvent();
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SpButton
                buttonType="accept"
                text={labels.patient.presentation.detail.deleteDeny}
                variant="h1PageTitle"
                onClick={() => {
                  setOpenToggleAvailability(false);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      }
    </SpDialog>
  );
};
