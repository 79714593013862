import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Chip,
  makeStyles,
  Checkbox,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { labels, psTranslate } from "../../shared/translations";
import { theme } from "../../../components/theme";
import SpTextInput from "../../../components/atoms/SpTextInput";
import SpButton from "../../../components/atoms/SpButton";
import SpLoader from "../../../components/atoms/SpLoader";
import {
  changeSlot,
  getOrganizationSchedule,
  updateOrganizationSchedule,
} from "../../../models/actions/Organization";
import { generateSlotsForDay } from "./OrganizationHelper";
import SpText from "../../../components/atoms/SpText";
import { useForm } from "react-hook-form";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SpAutocomplete from "../../../components/atoms/SpAutocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

const useStyles = makeStyles((themeStyle) => ({
  largeAvatar: {
    margin: "8px",
    width: themeStyle.spacing(5),
    height: themeStyle.spacing(5),
  },
}));

const DragAndDropPage = ({ props, professionals }) => {
  const [columns, setColumns] = useState(
    labels.week
      .map(({ key }) => key)
      .reduce((acc, day) => ({ ...acc, [day]: [] }), {})
  );
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedDay, setSelectedDay] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [time, setTime] = useState({ start: "", end: "" });
  const [slotTime, setSlotTime] = useState(30);
  const [loading, setLoading] = useState(false);
  const [newDate, setNewDate] = useState();
  const [changeDay, setChangeDay] = useState();
  const [changeStartTime, setChangeStartTime] = useState();
  const [changeEndTime, setChangeEndTime] = useState();

  const [oldProf, setOldProf] = useState();
  const [newProf, setNewProf] = useState();

  const [unavailablesDays, setUnavailableDays] = useState({});
  const classes = useStyles();

  const { control, register, handleSubmit, getValues, reset, setValue } =
    useForm();

  useEffect(() => {
    fetchData();
  }, [professionals]);

  const fetchData = async () => {
    let days = new Set();
    let tempUnavailable = {};
    let ret = await getOrganizationSchedule({});

    Object.keys(ret).map((idPat) => {
      if (!ret[idPat].unavailableDays) ret[idPat].unavailableDays = [];
      const startDate = ret[idPat]?.start_date;
      const endDate = ret[idPat]?.end_date;
      if (startDate && endDate) {
        setValue("start_date", startDate);
        setValue("end_date", endDate);
      }
      setUnavailableDays((prev) => ({
        ...prev,
        [idPat]: ret[idPat].unavailableDays,
      }));

      tempUnavailable[idPat] = [];
      if (ret[idPat]?.schedule)
        Object.keys(ret[idPat]?.schedule)?.map((day) => {
          days.add(day);
        });
    });
    setColumns(transformBackendToColumns(ret));
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (
      source.droppableId === "people" &&
      labels.week.map(({ key }) => key).includes(destination.droppableId)
    ) {
      const person = professionals.find((p) => p.id == result.draggableId);
      setSelectedPerson(person);
      setSelectedDay(destination.droppableId);
      setDialogOpen(true); // Apri il dialogo
    }
  };

  const handleConfirm = () => {
    // Aggiungi la persona alla colonna selezionata con gli orari
    setColumns((prev) => ({
      ...prev,
      [selectedDay]: [
        ...prev[selectedDay],
        { ...selectedPerson, time: `${time.start} - ${time.end}` },
      ],
    }));
    setDialogOpen(false);
    setSelectedPerson(null);
    setSelectedDay("");
    setTime({ start: "", end: "" });
  };

  const handleCancel = () => {
    setDialogOpen(false);
    setSelectedPerson(null);
    setSelectedDay("");
    setTime({ start: "", end: "" });
  };

  const handleDelete = (day, personId, time) => {
    setColumns((prev) => ({
      ...prev,
      [day]: prev[day].filter(
        (person) => !(person.time === time && person.id === personId)
      ),
    }));
  };

  const transformBackendToColumns = (backendData) => {
    const columns = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    };

    for (const [profId, profData] of Object.entries(backendData)) {
      if (profData?.schedule)
        for (const [day, slots] of Object.entries(profData.schedule)) {
          if (slots.length === 0) continue;
          const profSel = professionals.find(({ id }) => id == profId);
          // Raggruppa slot adiacenti
          const groupedSlots = [];
          let currentGroup = {
            startTime: slots[0].startTime,
            endTime: slots[0].endTime,
          };

          for (let i = 1; i < slots.length; i++) {
            if (slots[i].startTime === currentGroup.endTime) {
              currentGroup.endTime = slots[i].endTime;
            } else {
              groupedSlots.push(currentGroup);
              currentGroup = {
                startTime: slots[i].startTime,
                endTime: slots[i].endTime,
              };
            }
          }
          groupedSlots.push(currentGroup);

          // Aggiungi i gruppi al giorno corrispondente
          groupedSlots.forEach((group) => {
            columns[day].push({
              profileImage: profSel?.profileImage,
              name: profSel?.name,
              surname: profSel?.surname,
              id: parseInt(profId, 10),
              time: `${group.startTime} - ${group.endTime}`,
            });
          });
        }
    }

    return columns;
  };

  const saveSchedule = async () => {
    setLoading(true);
    const ret = await updateOrganizationSchedule({
      data: transformColumnsToBackendFormat(columns),
      slotTime: slotTime,
    });
    await fetchData();
    if (ret?.message) {
      props.snackbarShowMessage(ret.message);
    }
    if (ret?.error) {
      props.snackbarShowErrorMessage(ret.error);
    }
    setLoading(false);
  };

  const generateSlots = (startTime, endTime) => {
    const slots = [];
    let currentStartTime = startTime;

    while (currentStartTime < endTime) {
      const [startHour, startMinute] = currentStartTime.split(":").map(Number);
      const nextEndTime = new Date(0, 0, 0, startHour, startMinute + slotTime);

      const nextSlot = {
        startTime: currentStartTime,
        endTime: nextEndTime.toTimeString().slice(0, 5), // Prende "HH:MM"
      };

      slots.push(nextSlot);
      currentStartTime = nextSlot.endTime; // Aggiorna l'inizio del prossimo slot
    }

    return slots;
  };

  const transformColumnsToBackendFormat = (columns) => {
    const result = {};
    for (const [day, professionalsTemp] of Object.entries(columns)) {
      professionalsTemp.forEach((prof) => {
        if (!result[prof.id]) {
          result[prof.id] = {
            schedule: {},
            unavailableDays: [],
          };
        }

        // Se il giorno non esiste ancora nello schedule, inizializzalo come array vuoto
        if (!result[prof.id].schedule[day]) {
          result[prof.id].schedule[day] = [];
        }

        // Aggiungi gli slot dello schedule
        const [startTime, endTime] = prof.time.split(" - ");
        const slots = generateSlots(startTime, endTime, slotTime);

        result[prof.id].schedule[day] =
          result[prof.id].schedule[day].concat(slots);
        result[prof.id].unavailableDays = unavailablesDays[prof.id];
        result[prof.id]["start_date"] = getValues("start_date");
        result[prof.id]["end_date"] = getValues("end_date");
      });
    }

    professionals.map((prof) => {
      if (!result[prof.id]) {
        result[prof.id] = {
          schedule: {},
          unavailableDays: [],
          start_date: getValues("start_date"),
          end_date: getValues("end_date"),
        };
      }
    });
    return result;
  };

  const SelectProfessional = ({ prof, seleProf, label }) => {
    return (
      <SpAutocomplete
        style={{ width: "100%", height: "100%", maxHeight: "100%" }}
        formControlWidth={"100%"}
        label={label}
        options={professionals}
        getOptionLabel={(option) => `${option?.name} ${option?.surname}`}
        getOptionSelected={(opt, value) => {
          return opt.id === value.id;
        }}
        value={prof ?? []}
        onChange={(_, newValue) => seleProf(newValue)}
        renderOption={(props, option) => (
          <li
            key={`option_${props.id}`}
            style={{
              color: option.selected
                ? theme.colors.primary.white
                : theme.colors.primary.black,
            }}
          >
            <Checkbox
              name={props.id}
              icon={<CheckBoxOutlineBlankIcon small />}
              checkedIcon={<CheckBoxIcon small />}
              style={{
                marginRight: 5,
                color: option.selected
                  ? theme.colors.primary.white
                  : theme.colors.primary.black,
              }}
              checked={option.selected}
            />
            {`${props?.name} ${props?.surname}`}
          </li>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const _val = professionals.find(
              (x) => x.id === (option.id ?? option)
            );
            return (
              <Chip
                key={_val?.id}
                style={{
                  backgroundColor: theme.colors.primary.lightBlue,
                  color: theme.colors.primary.white,
                }}
                label={`${_val?.name} ${_val?.surname}`}
                size="medium"
                {...getTagProps({ index })}
              />
            );
          })
        }
      />
    );
  };

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container direction="row">
          <Grid item xs={4}>
            <SpTextInput
              name="slot_time"
              label={labels.requestsList.slot_time}
              type="number"
              maxValue="100"
              variant="text"
              value={slotTime}
              onChange={(e) => setSlotTime(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <SpTextInput
              style={{ minWidth: 300 }}
              name="start_date"
              defaultValue={moment().format("YYYY-MM-DD")}
              type="date"
              label={"start date"}
              placeholder={
                labels.patient.controlQuestions.selectFrequency.placeholder
              }
              inputRef={register}
            />
          </Grid>
          <Grid item xs={4}>
            <SpTextInput
              style={{ minWidth: 300 }}
              name="end_date"
              defaultValue={moment().add(1, "week").format("YYYY-MM-DD")}
              type="date"
              label={"end date"}
              placeholder={
                labels.patient.controlQuestions.selectFrequency.placeholder
              }
              inputRef={register}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* Sidebar */}
          <Grid item xs={2}>
            <Typography variant="h6">
              {labels.groups.table.header.professional}
            </Typography>
            <Droppable droppableId="people" isDropDisabled={true}>
              {(provided) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    border: "1px solid #ddd",
                    padding: 8,
                    borderRadius: 4,
                    background: "#f7f7f7",
                  }}
                >
                  {professionals.map((person, index) => (
                    <Draggable
                      key={person.id}
                      draggableId={`${person.id}`}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            display: "flex",
                            alignItems: "center",
                            marginBottom: 8,
                            background: theme.colors.primary.lightBlue,
                            borderRadius: 4,
                            padding: 8,
                          }}
                        >
                          <Avatar
                            imgProps={{
                              style: {
                                maxHeight: "100%",
                                maxWidth: "100%",
                                objectFit: "cover",
                              },
                            }}
                            className={classes.largeAvatar}
                            src={person?.profileImage}
                          />

                          <Typography>
                            {person.name} {person.surname}
                          </Typography>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Grid>

          {/* Columns for Days */}
          <Grid item xs={10}>
            <Grid container spacing={2}>
              {labels.week.map(({ key, name }) => (
                <Grid item xs={3} key={key}>
                  <Typography variant="h6">{name}</Typography>
                  <Droppable droppableId={key}>
                    {(provided) => (
                      <Paper
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          minHeight: 100,
                          padding: 8,
                          background: "#fafafa",
                        }}
                      >
                        {columns[key].map((person, index) => {
                          return (
                            <Box
                              key={person.id}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: 8,
                                background: theme.colors.primary.lightBlue,
                                borderRadius: 4,
                                padding: 8,
                              }}
                            >
                              <Avatar
                                imgProps={{
                                  style: {
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                    objectFit: "cover",
                                  },
                                }}
                                className={classes.largeAvatar}
                                src={person?.profileImage}
                              />
                              <Typography style={{ flexGrow: 1 }}>
                                {person.name} {person.surname} ({person.time})
                              </Typography>
                              <IconButton
                                size="small"
                                onClick={() =>
                                  handleDelete(key, person.id, person.time)
                                }
                              >
                                <ClearIcon />
                              </IconButton>
                            </Box>
                          );
                        })}
                        {provided.placeholder}
                      </Paper>
                    )}
                  </Droppable>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* Dialog for Time Selection */}
        <Dialog open={dialogOpen} onClose={handleCancel}>
          <DialogTitle>Set Time for {selectedPerson?.name}</DialogTitle>
          <DialogContent>
            <TextField
              label="Start Time"
              type="time"
              fullWidth
              value={time.start}
              onChange={(e) => setTime({ ...time, start: e.target.value })}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="End Time"
              type="time"
              fullWidth
              value={time.end}
              onChange={(e) => setTime({ ...time, end: e.target.value })}
              InputLabelProps={{ shrink: true }}
              style={{ marginTop: 16 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </DragDropContext>

      {professionals.map(({ id, name, surname }) => {
        return (
          <Grid style={{ padding: "1em" }}>
            <SpText variant="h1">{`${name} ${surname}`}</SpText>
            <Grid xs={12}>
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={3}>
                  <SpTextInput
                    name="slot_time"
                    label={labels.requestsList.unavailablesDays}
                    type="date"
                    maxValue="100"
                    variant="text"
                    style={{ width: "100%" }}
                    onChange={(e) => setNewDate(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2} style={{ paddingTop: "22px" }}>
                  <SpButton
                    type="submit"
                    style={{ height: 40 }}
                    buttonType={"accept"}
                    variant="none"
                    onClick={async () => {
                      setUnavailableDays((prevUnaval) => ({
                        ...prevUnaval,
                        [id]: [...prevUnaval[id], newDate],
                      }));
                    }}
                    text={labels.patient.medicalNotes.report.addButton}
                  />
                </Grid>
                <Grid item style={{ paddingTop: "22px" }}>
                  {unavailablesDays[id]?.map((option, index) => (
                    <Chip
                      key={`date_${index}`}
                      style={{
                        backgroundColor: theme.colors.primary.lightBlue,
                        color: "white",
                      }}
                      deleteIcon={<ClearIcon style={{ color: "white" }} />}
                      onDelete={() => {
                        setUnavailableDays((prevUnaval) => ({
                          ...prevUnaval,
                          [id]: prevUnaval[id].filter(
                            (day) => !moment(day).isSame(option)
                          ),
                        }));
                      }}
                      label={`${moment(option).format("DD/MM/YYYY")}`}
                      size="medium"
                    />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      <div style={{ padding: 20 }}>
        <SpButton
          type="submit"
          buttonType={"accept"}
          variant="none"
          onClick={async () => {
            await saveSchedule();
          }}
          text={labels.groups.groupDetail.actions.save}
        />
      </div>

      <SpText variant="h1">{labels.requestsList.changeProfessional}</SpText>
      <Grid container direction="row">
        <Grid item xs={3}>
          <SelectProfessional
            prof={oldProf}
            seleProf={setOldProf}
            label={labels.requestsList.oldProfessional}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectProfessional
            prof={newProf}
            seleProf={setNewProf}
            label={labels.requestsList.newProfessional}
          />
        </Grid>

        <Grid item xs={1}>
          <SpTextInput
            name="change_day"
            label={labels.requestsList.dateChange}
            type="date"
            maxValue="100"
            variant="text"
            style={{ width: "100%", paddingTop: "0.7em" }}
            onChange={(e) => setChangeDay(e.target.value)}
          />
        </Grid>

        <Grid item xs={1}>
          <SpTextInput
            name="start_time"
            label={labels.patient.viewPlan.addActivity.inputForm.start_time}
            type="time"
            maxValue="100"
            variant="text"
            style={{ width: "100%", paddingTop: "0.7em" }}
            onChange={(e) => setChangeStartTime(e.target.value)}
          />
        </Grid>

        <Grid item xs={1}>
          <SpTextInput
            name="end_time"
            label={labels.patient.viewPlan.addActivity.inputForm.end_time}
            type="time"
            maxValue="100"
            variant="text"
            style={{ width: "100%", paddingTop: "0.7em" }}
            onChange={(e) => setChangeEndTime(e.target.value)}
          />
        </Grid>
        <Grid item xs={3} style={{ width: "100%", paddingTop: "1.9em" }}>
          <SpButton
            type="submit"
            buttonType={"accept"}
            variant="none"
            onClick={async () => {
              const ret = await changeSlot({
                date: changeDay,
                start_time: moment(changeStartTime, "HH:mm:ss")
                  .utc()
                  .format("HH:mm:ss"),
                end_time: moment(changeEndTime, "HH:mm:ss")
                  .utc()
                  .format("HH:mm:ss"),
                start_slot: changeStartTime,
                end_slot: changeEndTime,
                old_professional_id: oldProf.id,
                new_professional_id: newProf.id,
              });

              if (ret.message) {
                props.snackbarShowMessage(ret.message);
                await fetchData();
              } else {
                props.snackbarShowErrorMessage(ret.error);
              }
            }}
            text={labels.groups.groupDetail.actions.save}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DragAndDropPage;
