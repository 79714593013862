import { Grid } from "@material-ui/core";
import moment from "moment-timezone";
import React, { Children, useEffect, useState, createRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import SpDialog from "../../components/atoms/SpDialog";
import SpCalendarDragAndDropItems from "../../components/atoms/SpDragAndDropCalendarItems";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import {
  getAllActivitiesTypes,
  getAllAssessmentTemplates,
  getAllFeedbackParameters,
  getAllProtocolTemplates,
  getAllRehabTemplates,
  getAllTrainingTemplates,
} from "../../models/actions/Activity";
import {
  createCalendarActivityOfGroup,
  getAllCalendarActivityByMonitGroup,
  updateCalendarActivityMonitGroup,
} from "../../models/actions/CalendarActivity";
import { getExerciseTemplates } from "../../models/actions/Excercise";
import { getGroupsById } from "../../models/actions/Groups";
import AgendaCalendarEventCard from "../agenda/AgendaCalendarEventCard";

import ColoredEventCell from "../agenda/ColoredEventCell";
import { getLanguage, labels, psTranslate } from "../shared/translations";
import {
  log,
  rollbar,
  momentDefinition,
  isFeatureFlagEnabled,
  downloadScreenshot,
  getCalendarEventsDates,
  formatDateTimeToLocal,
  getUserPermission,
} from "../../utils/common";
import { fetchDataGroup } from "../agenda/AgendaCalendarEventCardFunction";
import { titleCase } from "../../utils/common";
import printContext from "../../utils/printContext";
import GroupEditSidebar from "./shared/GroupEditSidebar";

import { getDefaultParameter } from "../../models/actions/Activity";
import ColoredEventCellDay from "../agenda/ColoredEventCellDay";
import ColoredEventCellWeek from "../agenda/ColoredEventCellWeek";
import { GroupActivityDialog } from "./GroupActivityPlanHelper";
import { getResourceFromServer } from "../../models/actions/Professionals";
import { useAuth0 } from "@auth0/auth0-react";

const DragAndDropCalendar = withDragAndDrop(Calendar);
const GroupActivityPlan = ({ setLoading, ...props }) => {
  const [groupsPatients, setGroupsPatients] = useState([]);
  const [openAddActivity, setOpenAddActivity] = useState(false);
  const [assessmentTemplateSelected, setAssessmentTemplateSelected] =
    useState(false);
  const [openDetailActivity, setOpenDetailActivity] = useState(false);
  const [activityTypeList, setActivityTypeList] = useState([]);
  const [loadParametersData, setLoadParametersData] = useState([]);
  const [loadParametersList, setLoadParametersList] = useState([]);
  const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);
  const [groupsPatientsSelected, setGroupsPatientsSelected] = useState([]);
  const [eventDetail, setEventDetail] = useState();
  const [eventsList, setEventsList] = useState([]);
  const [activityFormError, setActivityFormError] = useState(true);
  const [templatesList, setTemplatesList] = useState([]);
  const [draggedEvent, setDraggedEvent] = useState(null);
  const { groupId } = useParams();
  const [error, setError] = useState("");
  const [professionalsSelected, setProfessionalsSelected] = useState([]);
  const [groupDetail, setGroupDetail] = useState(null);
  const [renderProfessionalAlert, setRenderProfessionalAlert] = useState(<></>);
  const [isPrint, setIsPrint] = useState(false);
  const [isPrintAddActivity, setIsPrintAddActivity] = useState(false);
  const [fetchDataBe, setFetchDataBe] = useState(false);
  const [pdfFeatureFlag, setPdfFeatureFlag] = useState(false);
  const [defaultParameter, setDefaultParameter] = useState(false);
  const [monthSelected, setMonthSelected] = useState(moment());
  const [viewSelected, setViewSelected] = useState("month");
  const [subactivities, setSubactivities] = useState([]);
  const [startDate, setStartDate] = useState(moment());
  const [editGroupActivityPermission, setGroupActivityPermission] =
    useState(false);

  const history = useHistory();

  moment.locale(getLanguage(), momentDefinition);
  const localizer = momentLocalizer(moment);

  const reference = {};
  const [selectedPDF, setSelectedPDF] = useState([]);
  const [indexPDF, setIndexPDF] = useState(0);
  const [profileImage, setProfileImg] = useState();

  //React hook form
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    errors,
    watch,
  } = useForm({
    shouldUnregister: false,
  });

  useEffect(async () => {
    const tempPdfFeatureFlag = await isFeatureFlagEnabled(
      "analytics_pdf_export"
    );
    setPdfFeatureFlag(tempPdfFeatureFlag);
    if (!draggedEvent) {
      reset({ id_activity_type: null });
    } else {
      setDraggedEvent(null);
    }
  }, [openAddActivity]);

  useEffect(() => {
    fetchData();
  }, [monthSelected]);
  const { getAccessTokenSilently } = useAuth0();

  const fetchDataInitial = async () => {
    try {
      const [activityTypeResponse, feedbackParametersList, groupsDetailResult] =
        await Promise.all([
          getAllActivitiesTypes(getAccessTokenSilently),
          getAllFeedbackParameters(),
          getGroupsById({ id_group: groupId }),
        ]);
      setActivityTypeList(activityTypeResponse);

      const resultDefaultParameter = await getDefaultParameter();
      feedbackParametersList && setLoadParametersData(feedbackParametersList);
      feedbackParametersList && setDefaultParameter(resultDefaultParameter);
      if (groupsDetailResult) {
        setGroupDetail(groupsDetailResult);
        setGroupsPatients(groupsDetailResult.patients);

        if (groupsDetailResult.id_file) {
          const profileBase64 = await getResourceFromServer({
            id_file: groupsDetailResult.id_file,
          });
          if (profileBase64) {
            setProfileImg(profileBase64);
          }
        }
      }
    } catch (error) {
      rollbar.error("GroupActivityPlan - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchData = async () => {
    try {
      const { startOfMonth, endOfMonth } = getCalendarEventsDates({
        monthSelected,
      });

      const gpsDeviceTemp = await getUserPermission(
        "manage:gps-device",
        getAccessTokenSilently
      );

      const editGroupActictivityTemp = await getUserPermission(
        "manage:group-plan-activities",
        getAccessTokenSilently
      );
      setGroupActivityPermission(editGroupActictivityTemp);

      const responseEvents = await getAllCalendarActivityByMonitGroup({
        id_group: groupId,
        start_date: startOfMonth,
        end_date: endOfMonth,
        hide_feedbacks: gpsDeviceTemp ? true : false,
      });
      Rollbar.configure({
        checkIgnore: () => false,
      });
      rollbar.info(
        " GroupActivityPlan: - fetchData",
        JSON.stringify({
          responseEvents,
        })
      );
      Rollbar.configure({
        checkIgnore: () => true,
      });
      return updateEventsList(responseEvents);
    } catch (error) {
      rollbar.error("GroupActivityPlan - fetchData", error);
      props.snackbarShowErrorMessage(error);
    }
    return eventsList;
  };

  const MyCustomHeader = ({ label }) => (
    <div style={{ width: "100%", backgroundColor: "#333333", color: "#FFF" }}>
      <div style={{ color: "#FFF" }}>{label}</div>
    </div>
  );

  const handleNewEventSelected = (event) => {
    setLoadParametersList([]);
    setOpenAddActivity(true);
    setOpenDetailActivity(false);
    setValue("start_date", moment(event.start).format("YYYY-MM-DD"));
  };

  const handleEventDetailSelected = (event) => {
    setProfessionalsSelected(event?.assigned_professional);
    const tempSubactivities = activityTypeList?.find(
      ({ id }) => id == event.id_activity_type
    )?.subactivities;
    setSubactivities(tempSubactivities);
    setValue("id_activity_type", event.id_activity_type);
    setLoadParametersList(
      event.feedbacks?.map((feedback) => feedback.feedback_parameter) ?? []
    );
    setEventDetail(event);
    setOpenDetailActivity(true);
  };

  const getDateFormat = (giornoStringa, oraStringa) => {
    const [anno, mese, giorno] = giornoStringa.split("-");
    const [ora, minuti] = oraStringa.split(":");

    const dataOra = new Date(anno, mese - 1, giorno, ora, minuti);
    return dataOra;
  };

  const updateEventsList = (eventsListData) => {
    const newEventsList = [];
    try {
      eventsListData.map((event, eventIdx) => {
        const dateStartLocal = formatDateTimeToLocal({
          date: event?.start_date,
          time: event?.start_time,
        });
        const dateEndLocal = formatDateTimeToLocal({
          date: event?.start_date,
          time: event?.end_time,
        });

        newEventsList.push({
          id: event?.id,
          title: event?.activity_datum.activity_type?.name,
          description: "",
          start: getDateFormat(
            moment(dateStartLocal).format("YYYY-MM-DD"),
            moment(dateStartLocal).format("HH:mm:ss")
          ),
          end: getDateFormat(
            moment(dateStartLocal).format("YYYY-MM-DD"),
            moment(dateEndLocal).format("HH:mm:ss")
          ),
          start_time: moment(dateStartLocal).format("HH:mm:ss"),
          end_time: moment(dateEndLocal).format("HH:mm:ss"),
          type: event?.activity_datum?.name,
          event_note: event?.event_note,
          id_presentation: event?.id_presentation,
          id_subactivity: event?.id_subactivity,
          presentation: event?.presentation,
          assigned_professional: JSON.parse(event?.assigned_professional),
          id_activity_type: event?.activity_datum?.activity_type?.id,
          media: event?.activity_datum?.activity_type?.key
            ? require(`../../assets/activities/${event?.activity_datum?.activity_type?.key}.png`)
            : null,
          color: event?.color,
          data: event?.activity_datum?.data,
          feedbacks: event?.activity_feedbacks[0]
            ?.activity_resp_parameter_feedbacks
            ? event.activity_feedbacks[0].activity_resp_parameter_feedbacks
            : [],
          patientName: event?.patientName,
          groupName: event?.groupName,
          archiving_status: event?.archiving_status,
          patients: event?.patients,
          is_from_group_plan: event?.is_from_group_plan,
          id_activity_groups: event?.id_activity_groups,
        });
      });
      setEventsList(newEventsList);
      return newEventsList;
    } catch (error) {
      rollbar.error("GroupActivityPlan - updateEventsList", error);
    }
  };

  //rendering custom card
  const AgendaEventWrapper = (props) => {
    return React.cloneElement(Children.only(props.children), {
      style: {
        ...props.children.props.style,
        width: "100%",
        backgroundColor: props.event.color,
        padding: `8px`,
        color: "white !important",
      },
    });
  };

  const goToBackGeneral = (toolbar) => {
    try {
      let newDate = toolbar.date;

      switch (toolbar.view) {
        case "month":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth() - 1,
            newDate.getDate()
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;
        case "week":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate() - 7
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;
        case "day":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate() - 1
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;

        default:
          break;
      }
    } catch (error) {
      rollbar.error("GroupActivityPlan - goToBackGeneral", error);
    }
  };
  const goToNextGeneral = (toolbar) => {
    try {
      let newDate = toolbar.date;

      switch (toolbar.view) {
        case "month":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth() + 1,
            newDate.getDate()
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;
        case "week":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate() + 7
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;
        case "day":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate() + 1
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;

        default:
          break;
      }
    } catch (error) {
      rollbar.error("GroupActivityPlan - goToNextGeneral", error);
    }
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      goToBackGeneral(toolbar);
    };

    const goToNext = () => {
      goToNextGeneral(toolbar);
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.onNavigate("current", now);
      setMonthSelected(now);
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <SpText variant="monthYearCalendar">
          {toolbar.view === "day" && (
            <>
              <b>{date.format("DD")}</b>{" "}
            </>
          )}
          <b>{date.format("MMMM")}</b>
          <span> {date.format("YYYY")}</span>
        </SpText>
      );
    };

    return (
      <div
        container
        className="rbc-toolbar"
        spacing={1}
        style={{ marginBottom: "1%", justifyContent: "space-evenly" }}
      >
        <label style={{ justifyContent: "center" }} className={"label-date"}>
          {label()}
        </label>

        <div className="back-next-buttons">
          <button
            style={{ color: "#FFF" }}
            className="btn-back"
            onClick={() => {
              setViewSelected("month");
            }}
          >
            {labels.groups.plan.month}
          </button>
          <button
            style={{ color: "#FFF" }}
            className="btn-current"
            onClick={() => {
              setViewSelected("week");
            }}
          >
            {labels.groups.plan.week}
          </button>
          <button
            style={{ color: "#FFF" }}
            className="btn-next"
            onClick={() => {
              setViewSelected("day");
            }}
          >
            {labels.groups.plan.day}
          </button>
        </div>

        <div className="back-next-buttons">
          <button
            style={{ color: "#FFF" }}
            className="btn-back"
            onClick={goToBack}
          >
            {labels.groups.plan.prev}
          </button>
          <button
            style={{ color: "#FFF" }}
            className="btn-current"
            onClick={goToCurrent}
          >
            {labels.groups.plan.today}
          </button>
          <button
            style={{ color: "#FFF" }}
            className="btn-next"
            onClick={goToNext}
          >
            {labels.groups.plan.next}
          </button>
        </div>
      </div>
    );
  };

  const handleDragStart = (event) => {
    setDraggedEvent(event);
    setDisplayDragItemInCell(false);
  };

  const dragFromOutsideItem = () => {
    return draggedEvent;
  };

  const onDropFromOutside = async ({ start, end, allDay }) => {
    const event = {
      id: draggedEvent.id,
      title: draggedEvent.title,
      start: new Date(start),
      end: new Date(start),
      allDay: allDay,
    };

    await handleEventDropFromOutside({ draggedEvent, event, start, end });
    setDraggedEvent(null);
  };

  const handleEventDropFromOutside = async ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    const tempSubactivities = activityTypeList?.find(
      ({ id }) => id == draggedEvent?.id
    )?.subactivities;
    setSubactivities(tempSubactivities);
    setValue("id_activity_type", draggedEvent.id);
    setValue("start_date", moment(start).format("YYYY-MM-DD"));

    const gpsDevicePermission = await getUserPermission(
      "manage:gps-device",
      getAccessTokenSilently
    );

    if (!gpsDevicePermission) {
      const resultDefaultParameter = await getDefaultParameter();
      setLoadParametersList(resultDefaultParameter);
    } else {
      setLoadParametersList(loadParametersData);
    }
    setOpenAddActivity(true);
    setDisplayDragItemInCell(true);
  };

  const handleEventDrop = async ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    let allDay = event.allDay;

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }
    const nextEvents = eventsList.map((existingEvent) => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end, allDay }
        : existingEvent;
    });
    event.start_date = moment(start);
    event.end_date = moment(end);
    await updateActivity(event);
    setEventsList(nextEvents);
  };

  const updateActivity = async (data) => {
    data.emails_professionals_to_alert = professionalsSelected?.map(
      (professionalIter) => ({
        email: professionalIter.email,
        id: professionalIter.id,
        name: professionalIter?.name,
        surname: professionalIter?.surname,
      })
    );

    if (data.start_time && data.end_time) {
      const date = moment();
      const start_time = moment(data.start_time, "HH:mm:ss");
      const end_time = moment(data.end_time, "HH:mm:ss");
      date.set({
        hour: start_time.format("HH"),
        minute: start_time.format("mm"),
      });
      data.start_time = moment(date).utc().format("HH:mm");
      date.set({
        hour: end_time.format("HH"),
        minute: end_time.format("mm"),
      });
      data.end_time = moment(date).utc().format("HH:mm");
    }
    try {
      const updatedActivity = await updateCalendarActivityMonitGroup({
        id_subactivity: getValues("id_subactivity"),
        ...data,
      });
      if (!updatedActivity.error) {
        props.snackbarShowMessage(updatedActivity.message);
        await resetFields();
      } else {
        props.snackbarShowErrorMessage(updatedActivity.error);
      }
    } catch (error) {
      rollbar.error("GroupActivityPlan - updateActivity", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const createActivityObject = (data, id_activity_type) => {
    switch (id_activity_type) {
      case 1:
        data.details_data = {
          assessment_motivation: data.assessment_motivation,
          id_template: data?.id_template,
        };
        break;
      case 2:
        data.details_data = {
          name: data.name,
          treatments: data.treatments,
          treatments_motivation: data.treatments_motivation,
        };
        data.feedback_parameter = loadParametersList.filter(
          ({ key }) => key == "approvalrating"
        );
        break;
      case 3:
        data.details_data = {
          name: data.name,
          excercises: data.excercises,
          excercises_motivation: data.excercises_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 4:
        data.details_data = {
          name: data.name,
          training: data.training,
          training_motivation: data.training_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 5:
        data.details_data = { rest_motivation: data.rest_motivation };
        data.feedback_parameter = loadParametersList;
        break;
      case 6:
        data.details_data = {
          appointment_motivation: data.appointment_motivation,
        };
        break;
      case 7:
        data.details_data = {
          race_motivation: data.race_motivation,
          race_minutes: data.race_minutes,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 8:
        data.details_data = {
          name: data.name,
          rehabilitations: data.rehabilitations,
          rehabilitation_motivation: data.rehabilitation_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 9:
        data.details_data = {
          name: data.name,
          supplements: data.supplements,
          supplement_motivation: data.supplement_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      default:
        break;
    }
    // invio nella request gli ids dei professionisti selezionati
    data.emails_professionals_to_alert =
      professionalsSelected && professionalsSelected.length > 0
        ? professionalsSelected?.map((professionalIter) => ({
            email: professionalIter.email,
            id: professionalIter.id,
            name: professionalIter?.name,
            surname: professionalIter?.surname,
          }))
        : [];
    data.group_name = groupDetail.name;
    return data;
  };

  const createActivity = async (data) => {
    try {
      if (groupsPatientsSelected.length == 0) {
        setError(labels.groups.errors.selectAtLeastAPatient);
        return null;
      }
      setError(null);
      setValue("ids_patients", groupsPatientsSelected);
      const id_subactivity = getValues("id_subactivity");
      const date = moment(data.start_date, "YYYY-MM-DD");
      const start_time = moment(data.start_time, "HH:mm:ss");
      const end_time = moment(data.end_time, "HH:mm:ss");
      date.set({
        hour: start_time.format("HH"),
        minute: start_time.format("mm"),
      });
      data.start_time = moment(date).utc().format("HH:mm");
      date.set({
        hour: end_time.format("HH"),
        minute: end_time.format("mm"),
      });
      data.end_time = moment(date).utc().format("HH:mm");
      data.start_date = moment(date).format("YYYY-MM-DD");

      data = createActivityObject(data, getValues("id_activity_type"));

      Rollbar.configure({
        checkIgnore: () => false,
      });
      rollbar.info(
        " GroupActivityPlan: - createActivity",
        JSON.stringify({
          ...data,
          ids_patients: groupsPatientsSelected,
          is_from_group_plan: false,
          id_group: groupId,
          id_subactivity: id_subactivity,
        })
      );
      Rollbar.configure({
        checkIgnore: () => true,
      });

      const createdActivity = await createCalendarActivityOfGroup({
        ...data,
        ids_patients: groupsPatientsSelected,
        is_from_group_plan: false,
        id_group: groupId,
      });
      if (!createdActivity.error) {
        props.snackbarShowMessage(createdActivity.message);
        const newEvents = await resetFields();
        return [createdActivity.id, newEvents];
      } else {
        props.snackbarShowErrorMessage(createdActivity.error);
      }
    } catch (error) {
      rollbar.error("GroupActivityPlan - createActivity", error);
      props.snackbarShowErrorMessage(error);
    }

    return null;
  };

  const resetFields = () => {
    reset();
    setOpenAddActivity(false);
    setAssessmentTemplateSelected(false);
    setGroupsPatientsSelected([]);
    setLoadParametersList([]);
    return fetchData();
  };

  const watchStartTime = watch("start_time");
  const watchStartDate = watch("start_date");
  const watchActivityType = watch("id_activity_type");
  const watchTemplateSelect = watch("id_template");
  const watchEndTime = watch("end_time");

  useEffect(() => {
    setStartDate(getValues("start_date"));
  }, [watchStartDate]);

  const fetchTemplateByActType = async () => {
    try {
      setValue("id_template", "");
      setAssessmentTemplateSelected(false);
      let templateData = [];
      switch (getValues("id_activity_type")) {
        case 1:
          templateData = await getAllAssessmentTemplates();
          break;
        case 2:
          templateData = await getAllProtocolTemplates();
          break;
        case 3:
          templateData = await getExerciseTemplates();
          break;
        case 4:
          templateData = await getAllTrainingTemplates();
          break;
        case 8:
          templateData = await getAllRehabTemplates();
          break;
        case 9:
          templateData = [];
          break;
        default:
          break;
      }
      if (templateData) {
        setTemplatesList(templateData);
      }
    } catch (error) {
      rollbar.error("GroupActivityPlan - fetchTemplateByActType", error);
    }
  };

  useEffect(async () => {
    if (fetchDataBe) {
      await fetchData();
      setIsPrint(true);
      setFetchDataBe(false);
    }
  }, [fetchDataBe]);

  useEffect(() => {
    fetchDataInitial();
  }, []);

  useEffect(() => {
    setValue(
      "end_time",
      moment(getValues("start_time"), "HH:mm").add(1, "hour").format("HH:mm")
    );
    if (getValues("start_time") < new Date("22:00")) {
      setActivityFormError(true);
    } else {
      setActivityFormError(false);
    }
  }, [watchStartTime]);

  useEffect(() => {
    if (getValues("end_time") < new Date("22:00")) {
      setActivityFormError(true);
    } else {
      setActivityFormError(false);
    }
  }, [watchEndTime]);

  useEffect(() => {
    fetchTemplateByActType();
  }, [watchActivityType]);

  useEffect(async () => {
    const resultDataGroup = await fetchDataGroup({
      groupId,
      isGroupCard: true,
      setProfessionalsSelected: setProfessionalsSelected,
      loadParametersList: professionalsSelected,
    });
    setRenderProfessionalAlert(resultDataGroup.renderProfessionalAlert);
  }, [professionalsSelected]);

  const createReference = () => {
    reference[indexPDF] = createRef(null);
    return reference;
  };

  const dateHeader = ({ date, label }) => {
    if (moment(monthSelected).format("MM") === moment(date).format("MM")) {
      return <h3 style={{ color: "lightGray" }}>{label}</h3>;
    } else {
      return <h3 style={{ color: "gray" }}>{label}</h3>;
    }
  };

  return (
    <printContext.Provider
      value={{
        isPrint: isPrintAddActivity,
        setIsPrint: setIsPrintAddActivity,
      }}
    >
      <div>
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          <Grid item xs={12} container spacing={2} direction="row">
            <Grid item xs={12}>
              <GroupEditSidebar
                props={props}
                groupId={groupId}
                history={history}
                currentGroup={groupDetail}
              />
            </Grid>
            {editGroupActivityPermission && (
              <SpCalendarDragAndDropItems
                activitiesAvailable={activityTypeList}
                handleDragStart={handleDragStart}
              />
            )}
            <Grid
              item
              xs={editGroupActivityPermission ? 11 : 12}
              style={{ minHeight: "750px" }}
            >
              <DragAndDropCalendar
                selectable
                step={30}
                min={new Date(0, 0, 0, 5, 0, 0)}
                max={new Date(0, 0, 0, 23, 0, 0)}
                events={eventsList}
                scrollToTime={new Date()}
                defaultDate={new Date()}
                view={viewSelected}
                onEventDrop={handleEventDrop}
                popup={true}
                dragFromOutsideItem={
                  displayDragItemInCell ? dragFromOutsideItem : null
                }
                onDropFromOutside={onDropFromOutside}
                handleDragStart={handleDragStart}
                onSelectEvent={handleEventDetailSelected}
                onSelectSlot={
                  editGroupActivityPermission
                    ? handleNewEventSelected
                    : () => {}
                }
                draggableAccessor={(event) =>
                  !(
                    event.archiving_status ===
                      "completed_and_feedback_not_sent" ||
                    event.archiving_status === "completed_and_feedback_sent"
                  )
                }
                tooltipAccessor={(event) => {
                  const tooltip = Object.entries(event?.data ?? {}).find(
                    ([key]) => key.includes("_motivation")
                  )?.[1];
                  return tooltip ? tooltip : event.title;
                }}
                eventPropGetter={(event, start, end, isSelected) => {
                  const backgroundColor = event.color;
                  const style = {
                    backgroundColor: backgroundColor,
                    borderRadius: "5px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    border: "1px solid white",
                    display: "block",
                  };
                  return {
                    style: style,
                  };
                }}
                messages={{
                  showMore: (total) => (
                    <a
                      style={{ cursor: "pointer" }}
                      onMouseOver={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      {`+${total} ${labels.groups.plan.viewMore}`}
                    </a>
                  ),
                }}
                components={{
                  timeGutterHeader: (props) => (
                    <div style={{ color: "red" }}>{props.children}</div>
                  ),
                  month: {
                    toolbar: (toolbar) => CustomToolbar(toolbar),

                    header: MyCustomHeader,
                    dateHeader: dateHeader,

                    event: ColoredEventCell,
                    eventWrapper: AgendaEventWrapper,
                  },
                  week: {
                    toolbar: (toolbar) => CustomToolbar(toolbar),

                    header: MyCustomHeader,
                    dateHeader: dateHeader,

                    event: ColoredEventCellWeek,
                    eventWrapper: AgendaEventWrapper,
                  },
                  day: {
                    toolbar: (toolbar) => CustomToolbar(toolbar),

                    header: MyCustomHeader,
                    dateHeader: dateHeader,

                    event: ColoredEventCellDay,
                    eventWrapper: AgendaEventWrapper,
                  },
                }}
                localizer={localizer}
              />
            </Grid>
          </Grid>
        </Grid>
        {/* -------------------------- */}
        {/* DIALOG FOR UPDATE ACTIVITY */}
        {/* -------------------------- */}
        {eventDetail && (
          <SpDialog
            variant="bigDialog"
            open={openDetailActivity}
            setOpen={setOpenDetailActivity}
            image={profileImage}
            title={
              eventDetail?.groupName ? "" : titleCase(eventDetail?.patientName)
            }
          >
            <AgendaCalendarEventCard
              groupDetail={groupDetail}
              isGroupCard={eventDetail?.id_activity_groups ? true : false}
              groupId={eventDetail?.id_activity_groups ? groupId : ""}
              groupsPatients={groupsPatients}
              setGroupsPatientsSelected={setGroupsPatientsSelected}
              groupsPatientsSelected={groupsPatientsSelected}
              renderProfessionalAlert={renderProfessionalAlert}
              event={eventDetail}
              fetchData={fetchData}
              setOpenDetailActivity={setOpenDetailActivity}
              updateActivity={updateActivity}
              openDetailActivity={openDetailActivity}
              createActivityObject={createActivityObject}
              loadParametersData={loadParametersData}
              loadParametersList={loadParametersList}
              setLoadParametersList={setLoadParametersList}
              title={
                eventDetail?.groupName
                  ? titleCase(eventDetail?.groupName)
                  : titleCase(eventDetail?.patientName)
              }
              subactivities={subactivities}
              isPrintPassed={isPrintAddActivity}
              setValue={setValue}
              getValues={getValues}
              register={register}
              handleSubmit={handleSubmit}
              control={control}
              activitySelectedKey={
                activityTypeList.find(
                  ({ id }) => id == getValues("id_activity_type")
                )?.key
              }
            />
          </SpDialog>
        )}

        {/* -------------------------- */}
        {/* DIALOG FOR CREATE ACTIVITY */}
        {/* -------------------------- */}
        <GroupActivityDialog
          openAddActivity={openAddActivity}
          setOpenAddActivity={setOpenAddActivity}
          resetFields={resetFields}
          title={groupDetail?.name}
          createActivity={createActivity}
          setLoading={setLoading}
          handleEventDetailSelected={handleEventDetailSelected}
          onSubmit={handleSubmit(createActivity)}
          ref={createRef()}
          isPrintAddActivity={isPrintAddActivity}
          groupsPatientsSelected={groupsPatientsSelected}
          setGroupsPatientsSelected={setGroupsPatientsSelected}
          groupsPatients={groupsPatients}
          renderProfessionalAlert={renderProfessionalAlert}
          activityTypeList={activityTypeList}
          control={control}
          getValues={getValues}
          setValue={setValue}
          templatesList={templatesList}
          subactivities={subactivities}
          watchTemplateSelect={watchTemplateSelect}
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          defaultParameter={defaultParameter}
          setLoadParametersList={setLoadParametersList}
          register={register}
          error={error}
          profileImage={profileImage}
          startDate={startDate}
        />
      </div>
    </printContext.Provider>
  );
};

export default withSnackbar(GroupActivityPlan);
