import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { labels, psTranslate } from "./shared/translations";
import SpButton from "../components/atoms/SpButton";
// Registra i componenti necessari di Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TreatmentHelper = ({ activities, professional }) => {
  function transformData(inputArray) {
    const rawData = {
      activities: {},
    };

    inputArray.forEach((item, index) => {
      // Controlla che l'attività abbia un nome valido e una durata valida
      if (item.activity && item.duration !== null && !isNaN(item.duration)) {
        const activityName =
          item.activity.trim() || `${psTranslate("Altro")}${index + 1}`; // Usa un nome di fallback se l'attività è vuota
        rawData.activities[activityName] = item.duration;
      }
    });

    return rawData;
  }

  // Funzione per preparare i dati
  const prepareChartData = (data, topN = 15) => {
    const sortedActivities = Object.entries(data.activities).sort(
      ([, a], [, b]) => b - a
    );
    const topActivities = sortedActivities.slice(0, topN);
    const otherActivities = sortedActivities.slice(topN);
    const otherTotal = otherActivities.reduce(
      (sum, [, value]) => sum + value,
      0
    );

    const labels = topActivities.map(([key]) => psTranslate(key));
    const values = topActivities.map(([, value]) => value);

    if (otherTotal > 0) {
      labels.push(psTranslate("Altro"));
      values.push(otherTotal);
    }

    return { labels, values, otherActivities };
  };

  // Stato per i dati del grafico
  const newData = transformData(activities);
  const [chartData, setChartData] = useState(prepareChartData(newData));
  const [mainData, setMainData] = useState(prepareChartData(newData));

  // Funzione per gestire il clic su "Altro"
  const handleAltroClick = () => {
    if (chartData.labels.includes(psTranslate("Altro"))) {
      setChartData({
        labels: chartData.otherActivities.map(([key]) => psTranslate(key)),
        values: chartData.otherActivities.map(([, value]) => value),
        otherActivities: [],
      });
    }
  };

  // Funzione per tornare al grafico iniziale
  const handleResetClick = () => {
    setChartData(mainData);
  };

  return (
    <>
      <div style={{ height: "700px" }}>
        <Bar
          id={`activityTreatmentProfessional${professional}`}
          data={{
            labels: chartData.labels,
            datasets: [
              {
                label: "Duration [minutes]",
                data: chartData.values,
                backgroundColor: "rgba(255, 99, 132, 0.6)",
                datalabels: {
                  color: "white", // Colore del testo dei numeri
                  font: {
                    weight: "bold",
                    size: 12,
                  },
                },
              },
            ],
          }}
          options={{
            indexAxis: "y", // o "y" se hai un grafico orizzontale
            responsive: true,
            maintainAspectRatio: false,
            onClick: (event, elements) => {
              if (elements.length > 0) {
                const elementIndex = elements[0].index;
                const label = chartData.labels[elementIndex];
                if (label === psTranslate("Altro")) handleAltroClick();
              }
            },
            plugins: {
              legend: { display: true, position: "top" },
            },
            scales: {
              y: {
                beginAtZero: true,
                title: { display: true, text: "Duration [minutes]" },
              },
              x: {
                title: {
                  display: true,
                  text: labels.patient.graphReport.filtersButton.activity,
                },
              },
            },
          }}
        />
      </div>
      {activities.length !== 0 && (
        <SpButton
          buttonType={"accept"}
          text={labels.bodymap.resetButton}
          onClick={handleResetClick}
        />
      )}
    </>
  );
};

export default TreatmentHelper;
