import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "../../App.css";
import { SpAutocomplete } from "../../components/atoms/SpAutocomplete";
import SpButton from "../../components/atoms/SpButton";
import SpDialog from "../../components/atoms/SpDialog";
import { SpSelect, SpSelectMenuItem } from "../../components/atoms/SpSelect";
import SpText from "../../components/atoms/SpText";
import SpTextArea from "../../components/atoms/SpTextArea";
import SpTextInput from "../../components/atoms/SpTextInput";
import A1_AssessmentInputDetails from "../agenda/inputDetails/A1_AssessmentInputDetails";
import A2_TreatmentInputDetails from "../agenda/inputDetails/A2_TreatmentInputDetails";
import A3_ExerciseInputDetails from "../agenda/inputDetails/A3_ExerciseInputDetails";
import A4_TrainingInputDetails from "../agenda/inputDetails/A4_TrainingInputDetails";
import A5_RestInputDetails from "../agenda/inputDetails/A5_RestInputDetails";
import A6_OtherInputDetails from "../agenda/inputDetails/A6_OtherInputDetails";
import A7_RaceInputDetails from "../agenda/inputDetails/A7_RaceInputDetails";
import A8_RehabilitationInputDetails from "../agenda/inputDetails/A8_RehabilitationInputDetails";
import A9_SupplementDetails from "../agenda/inputDetails/A9_SupplementDetails";
import { labels, psTranslate } from "../shared/translations";
import { titleCase } from "../../utils/common";
import moment from "moment";
const INPUT_DETAILS = [
  null, //IDb indexes start from 1
  A1_AssessmentInputDetails,
  A2_TreatmentInputDetails,
  A3_ExerciseInputDetails,
  A4_TrainingInputDetails,
  A5_RestInputDetails,
  A6_OtherInputDetails,
  A7_RaceInputDetails,
  A8_RehabilitationInputDetails,
  A9_SupplementDetails,
];
const getSubactivities = ({ activityTypeList, getValues, control }) => {
  let acitivitySelected;
  if (activityTypeList)
    acitivitySelected = activityTypeList.find(
      ({ id }) => id == getValues("id_activity_type")
    );

  if (
    acitivitySelected &&
    !["rehabilitation", "treatment", "supplement"].includes(
      acitivitySelected.key
    )
  )
    return (
      <Grid item xs={4}>
        <Controller
          render={(props) => (
            <SpSelect
              label={labels.patient.patientPlan.selectSubactivity}
              value={props.value}
              formControlWidth={"100%"}
              selectPlaceholder={labels.patient.patientPlan.selectSubactivity}
              onChange={(e) => {
                props.onChange(e.target.value);
              }}
            >
              {activityTypeList
                .find(({ id }) => id == getValues("id_activity_type"))
                ?.subactivities.map(({ id, name }) => (
                  <SpSelectMenuItem key={id} value={id}>
                    {name}
                  </SpSelectMenuItem>
                ))}
            </SpSelect>
          )}
          defaultValue={""}
          name={`id_subactivity`}
          control={control}
        />
      </Grid>
    );

  return <></>;
};

export const GroupActivityDialog = ({
  openAddActivity,
  setOpenAddActivity,
  resetFields,
  title,
  onSubmit,
  setLoading,
  createActivity,
  handleEventDetailSelected,
  ref,
  isPrintAddActivity,
  groupsPatientsSelected,
  setGroupsPatientsSelected,
  groupsPatients,
  renderProfessionalAlert,
  activityTypeList,
  control,
  getValues,
  setValue,
  templatesList,
  subactivities,
  watchTemplateSelect,
  loadParametersData,
  loadParametersList,
  defaultParameter,
  setLoadParametersList,
  register,
  error,
  profileImage,
  startDate,
  ...props
}) => {
  return (
    <SpDialog
      variant="bigDialog"
      open={openAddActivity}
      setOpen={setOpenAddActivity}
      onCloseFunctions={resetFields}
      image={profileImage}
      title={""}
    >
      <form onSubmit={onSubmit}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div ref={ref}>
            <Grid item style={{ maxWidth: "100%", marginBottom: "1%" }}>
              <Grid item container xs={12} direction="row">
                <Grid item xs={8}>
                  {isPrintAddActivity && (
                    <h2 color="black">
                      {groupDetail?.name ? titleCase(title) : ""}
                    </h2>
                  )}
                </Grid>
              </Grid>

              <Grid item container xs={12} direction="row">
                <Grid item xs={2}>
                  <SpTextInput
                    style={{ width: "100%", paddingTop: "0.5em" }}
                    type="time"
                    name="start_time"
                    min="05:00"
                    max="22:00"
                    defaultValue={
                      moment().isSame(moment(startDate, "YYYY-MM-DD"), "day")
                        ? moment().format("HH:mm")
                        : "08:00"
                    }
                    inputRef={register({ required: true })}
                    label={
                      labels.patient.viewPlan.addActivity.inputForm.start_time
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <SpTextInput
                    style={{ width: "100%", paddingTop: "0.5em" }}
                    type="time"
                    name="end_time"
                    min="05:00"
                    max="22:00"
                    defaultValue="09:00"
                    inputRef={register({ required: true })}
                    label={
                      labels.patient.viewPlan.addActivity.inputForm.end_time
                    }
                  />
                </Grid>

                <Grid item xs={4}>
                  <Controller
                    render={(props) => (
                      <SpSelect
                        style={{ width: "100%" }}
                        label={labels.groups.plan.chooseActivity}
                        value={props.value ?? []}
                        onChange={(e) => {
                          props.onChange(e.target.value);
                          setValue("quantity_training", 0);
                          setValue("quantity_treatment", 0);
                          setValue("quantity_rehabilitation", 0);
                          setValue("quantity_excercise", 0);
                        }}
                      >
                        {activityTypeList.map((p) => (
                          <SpSelectMenuItem key={p.id} value={p.id}>
                            <div
                              style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={require(`../../assets/activities/${p.key}${
                                  props.value === p.id ? "" : "_black"
                                }.png`)}
                                style={{ maxWidth: 25, maxHeight: 25 }}
                                alt={"img"}
                              />
                              <SpText
                                color={props.value === p.id ? "white" : "black"}
                                style={{
                                  color: "black !important",
                                  marginLeft: "5px",
                                }}
                              >
                                {psTranslate(p.name)}
                              </SpText>
                            </div>
                          </SpSelectMenuItem>
                        ))}
                      </SpSelect>
                    )}
                    defaultValue={""}
                    name={`id_activity_type`}
                    control={control}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={12}
                spacing={1}
                direction="row"
                style={{ paddingBottom: "2em" }}
              >
                {getSubactivities({ activityTypeList, getValues, control })}
                {templatesList.length > 0 && (
                  <Grid item xs={4}>
                    <Controller
                      render={(props) => (
                        <SpSelect
                          label={
                            labels.patient.viewPlan.addActivity.inputForm
                              .chooseTemplateActivity
                          }
                          style={{ marginTop: "1%" }}
                          value={props.value}
                          onChange={(e) => props.onChange(e.target.value)}
                        >
                          {templatesList.map((p) => (
                            <SpSelectMenuItem key={p.id} value={p.id}>
                              {p.name}
                            </SpSelectMenuItem>
                          ))}
                        </SpSelect>
                      )}
                      defaultValue={""}
                      name={`id_template`}
                      control={control}
                    />
                  </Grid>
                )}

                <Grid item xs={4} style={{ paddingTop: "1em" }}>
                  {renderProfessionalAlert}
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ paddingBottom: "1em" }}>
                <SpAutocomplete
                  isPrint={isPrintAddActivity}
                  multiple
                  style={{ width: "100%" }}
                  formControlWidth={"100%"}
                  label={
                    labels.groups.groupActivityPlan.addActivity.patientsSelect
                      .label
                  }
                  value={groupsPatientsSelected}
                  selectPlaceholder={
                    labels.groups.groupActivityPlan.addActivity.patientsSelect
                      .placeholder
                  }
                  onChange={(e, newValue) => {
                    setGroupsPatientsSelected(newValue);
                  }}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <SpText
                        style={{ marginLeft: "8px" }}
                        variant={"h4ComponentLabel"}
                      >
                        {`${option.givenName} ${option.familyName}`}
                      </SpText>
                    ))
                  }
                  options={groupsPatients}
                  getOptionLabel={(option) =>
                    `${option.givenName} ${option.familyName}`.toString()
                  }
                />
                {/* I pulsanti non devono essere mostrati nel PDF */}
                {!isPrintAddActivity && (
                  <SpButton
                    buttonType={"accept"}
                    style={{ height: 20 }}
                    variant="h4ComponentLabel"
                    onClick={() => {
                      setGroupsPatientsSelected(
                        groupsPatientsSelected?.length ===
                          groupsPatients?.length
                          ? []
                          : groupsPatients
                      );
                    }}
                    text={
                      groupsPatientsSelected?.length === groupsPatients?.length
                        ? labels.groups.plan.deselectAll
                        : labels.groups.plan.selectAll
                    }
                  />
                )}
              </Grid>
            </Grid>
            {error && <SpText variant="h4ComponentLabelError">{error}</SpText>}

            <div style={{ marginTop: "1%", marginBottom: "1%" }}>
              {renderInputDetails({
                getValues,
                setValue,
                subactivities,
                watchTemplateSelect,
                loadParametersData,
                loadParametersList,
                defaultParameter,
                setLoadParametersList,
                control,
                register,
              })}
            </div>
            <SpTextArea name="event_note" label="Note" inputRef={register} />

            {/* I pulsanti non devono essre mostrati nel PDF */}
            {!isPrintAddActivity && (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={6}>
                  <SpButton
                    buttonType={"accept"}
                    variant="none"
                    text={labels.patient.viewPlan.addActivity.title}
                    style={{ marginTop: "4%", width: "100%" }}
                    type="submit"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SpButton
                    buttonType={"accept"}
                    variant="none"
                    text={labels.patient.viewPlan.addActivity.addAndCompile}
                    style={{ marginTop: "4%", width: "100%" }}
                    type="submit"
                    onClick={async (e) => {
                      e.preventDefault();
                      setLoading(true);
                      const result = await createActivity(getValues());
                      if (result) {
                        const [idEvent, newEventList] = result;
                        const newEvent = newEventList.find(
                          (evnt) => evnt.id === idEvent
                        );
                        if (newEvent) handleEventDetailSelected(newEvent);
                      }
                      setLoading(false);
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </form>
    </SpDialog>
  );
};

//method to render input details based on the type of activity selected
const renderInputDetails = ({
  getValues,
  setValue,
  subactivities,
  watchTemplateSelect,
  loadParametersData,
  loadParametersList,
  defaultParameter,
  setLoadParametersList,
  control,
  register,
}) => {
  const Detail = INPUT_DETAILS[getValues("id_activity_type")];
  return (
    Detail && (
      <Detail
        subactivities={subactivities}
        idActivityType={getValues("id_activity_type")}
        watchTemplateSelect={watchTemplateSelect}
        selectedTemplate={getValues("id_template")}
        loadParametersData={loadParametersData}
        loadParametersList={loadParametersList}
        defaultParameter={defaultParameter}
        setLoadParametersList={setLoadParametersList}
        control={control}
        setValue={setValue}
        getValues={getValues}
        register={register}
        isPrint={false}
      />
    )
  );
};
