import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import { theme } from "../../../components/theme";
import SpText from "../../../components/atoms/SpText";
import { labels, psTranslate } from "../../shared/translations";
import SpButton from "../../../components/atoms/SpButton";
import { SpTable, SpTableCell, SpTableRow } from "../../../components/bundles";
import { SpAutocomplete } from "../../../components/atoms/SpAutocomplete";
import SpSearch from "../../../components/atoms/SpSearch";

import { Grid, TableCell } from "@material-ui/core";
import SpIconButton from "../../../components/atoms/SpIconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import { isMobile } from "react-device-detect";
import { styled } from "../../../components/styled";
import { getDevicesPlayerByProfessionalId } from "../../../models/actions/Devices";
import { getPatientsByProfessionalDetails } from "../../../models/actions/Professionals";
import moment from "moment";
import jwt_decode from "jwt-decode";

import {
  updateDevicePatient,
  syncDevicePatientsToPatients,
} from "../../../models/actions/Devices";
import SpLoader from "../../../components/atoms/SpLoader";
import { useAuth0 } from "@auth0/auth0-react";
import { titleCase } from "../../../utils/common";
import SpTooltip from "../../../components/atoms/SpTooltip";
const MyLabGpsDevicesPatient = (props) => {
  const idProfessional = localStorage.getItem("userId");
  const history = useHistory();
  const [devicesPatient, setDevicesPatient] = useState({});
  const [devicesPatientUpdate, setDevicesPatientUpdate] = useState({});

  const [patientsUpdated, setPatientsUpdated] = useState(false);
  const [patients, setPatients] = useState([]);
  const [patientsList, setPatientsList] = useState([]);

  const [table, setTable] = useState(<></>);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [searchString, setSearchString] = useState();

  const { control, setValue } = useForm({
    shouldUnregister: false,
  });

  const updatePatients = async () => {
    return new Promise(async (resolve, reject) => {
      setLoading(true);
      try {
        const accessToken = await getAccessTokenSilently();
        const accessTokenDecoded = jwt_decode(accessToken);
        const permissions = accessTokenDecoded?.permissions;

        // add device producer key based on auth0 permissions
        let devicePermissions = ["ksport"];
        if (permissions.includes("manage-statsport"))
          devicePermissions.push("statsport");
        if (permissions.includes("manage-gpexe"))
          devicePermissions.push("gpexe");
        devicePermissions.push("catapult");

        let results = await getDevicesPlayerByProfessionalId({
          device_permissions: devicePermissions,
        });

        results = results.sort((pat1, pat2) =>
          pat1.surname.localeCompare(pat2.surname)
        );

        setDevicesPatient(results);
        setDevicesPatientUpdate(results);
        // get dei pazienti dal DB
        let tempPatients = await getPatientsByProfessionalDetails();

        //ordino gli atleti
        tempPatients = tempPatients.sort((pat1, pat2) =>
          pat1.familyName.localeCompare(pat2.familyName)
        );
        setPatientsList(tempPatients);
        setPatients(tempPatients);
        setLoading(false);

        setPatientsUpdated(true);
        resolve(true);
      } catch (error) {
        setLoading(false);
        props.snackbarShowErrorMessage(error);
        reject();
      }
    });
  };

  useEffect(async () => {
    await updatePatients();
  }, []);

  useEffect(async () => {
    if (patientsUpdated) {
      const tempWidget = renderTable(devicesPatientUpdate);
      setTable(tempWidget);
      setPatientsUpdated(false);
    }
  }, [patientsUpdated]);

  const syncPatients = async () => {
    setLoading(true);
    const idProfessional = localStorage.getItem("userId");
    const resultSync = await syncDevicePatientsToPatients({
      id_professional: idProfessional,
    });
    setLoading(false);

    if (resultSync.message) {
      await updatePatients();
      props.snackbarShowMessage(resultSync.message);
    } else {
      props.snackbarShowErrorMessage(resultSync.error);
    }
  };

  const renderTable = (tempPatients) => {
    /**
     * Table render with Device Patient details
     *
     * @param {object} tempPatients : device patient id
     */
    return (
      <SpTable
        headCells={patientHeadCells}
        rows={tempPatients}
        pagination={true}
        rowKey="id"
        padding={false}
        notCheckable={false}
        tableContainerMaxHeight={
          isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
        }
      >
        <PatientsRow />
      </SpTable>
    );
  };

  {
    /** CUSTOM STYLED COMPONENTS **/
  }
  const TitleToolbarSection = styled("div")({
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-start",
  });

  const StyledTableColumn = styled("div")({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginBottom: "5%",
  });

  {
    /** HEADER CELLS **/
  }
  const patientHeadCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.gpsDevices.patients.givenName,
      isAction: false,
    },
    {
      id: "surname",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.gpsDevices.patients.familyName,
      isAction: false,
    },
    {
      id: "birthDate",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.gpsDevices.patients.birthDate,
      isAction: false,
    },
    {
      id: "patient",
      numeric: false,
      disablePadding: false,
      label: labels.mylab.gpsDevices.patients.link,
      isAction: false,
    },
  ];

  const udpatePatient = async ({ id, id_patient }) => {
    /**
     * Update Device Patient with Patient selected
     *
     * @param {number} id : device patient id
     * @param {number} id_patient : patient id
     */
    const response = await updateDevicePatient({
      id_device_patient: id,
      id_patient: id_patient,
    });
    if (response.error) {
      props.snackbarShowErrorMessage(response.error);
    } else {
      props.snackbarShowMessage(response.message);
    }
  };

  const filterPatients = (e) => {
    setSearchString(e.currentTarget.value);
    let newDisplayFilters = devicesPatientUpdate?.filter((patient) =>
      `${patient.name} ${patient.surname}`
        .toString()
        .toLowerCase()
        .includes(e.currentTarget.value.toString().toLowerCase())
    );
    setPatients(newDisplayFilters);
    if (newDisplayFilters.length > 0 && e.currentTarget.value.length > 0) {
      const tempWidget = renderTable(newDisplayFilters);
      setTable(tempWidget);
    }
    if (e.currentTarget.value.length === 0) {
      setPatients(patientsList);
      const tempWidget = renderTable(devicesPatientUpdate);
      setTable(tempWidget);
    }
  };

  const PatientsRow = ({ row }) => {
    /**
     * For each row this function render all the fields of the relative Patient
     *
     * @param {object} row : The single row to be rendered
     */
    return (
      <>
        <SpTableRow tabIndex={-1} key={row?.id}>
          <SpTableCell>
            <SpText variant="tableText">
              {row?.name && titleCase(row?.name)}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">
              {row?.surname && titleCase(row?.surname)}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            <SpText variant="tableText">
              {row?.birth_date && moment(row?.birth_date).format("DD/MM/YYYY")}
            </SpText>
          </SpTableCell>
          <SpTableCell>
            {/* 
                IF row has id_patient means that the device patient is connected to the patient
            */}
            {row.id_patient ? (
              <>
                <Grid
                  style={{ paddingLeft: "1%" }}
                  direction="row"
                  container
                  spacing={2}
                >
                  <Grid item xs={10} container alignItems={"center"}>
                    <SpAutocomplete
                      formControlWidth={"100%"}
                      // label={labels.patient.presentation.add.selectRegion}
                      disabled={true}
                      value={row.id_patient}
                      selectPlaceholder={
                        labels.mylab.gpsDevices.action.selectPatient
                      }
                      options={patients}
                      getOptionLabel={(option) =>
                        titleCase(
                          psTranslate(
                            `${row.patient?.givenName} ${row.patient?.familyName}`
                          )
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={2} container alignItems={"center"}>
                    <SpTooltip
                      title={labels.mylab.gpsDevices.action.cancelLink}
                    >
                      <SpIconButton variant="lightBlue" small="true">
                        <CancelIcon
                          onClick={async () => {
                            row.id_patient = null;
                            setDevicesPatientUpdate(devicesPatient);
                            await udpatePatient({
                              id: row.id,
                              id_patient: null,
                            });
                            setPatientsUpdated(true);
                          }}
                          style={{
                            cursor: "default",
                            color: "red",
                          }}
                        />
                      </SpIconButton>
                    </SpTooltip>
                  </Grid>
                </Grid>
              </>
            ) : (
              /* 
                Autocomplete for link device patient to patient 
              */
              <SpAutocomplete
                formControlWidth={"100%"}
                // label={labels.patient.presentation.add.selectRegion}
                value={row.id_patient}
                selectPlaceholder={labels.mylab.gpsDevices.action.selectPatient}
                onChange={async (e, newValue) => {
                  row.id_patient = newValue?.id;

                  //   setDevicesPatientUpdate(devicesPatient);
                  await udpatePatient({
                    id: row.id,
                    id_patient: row.id_patient,
                  });
                  let results = await getDevicesPlayerByProfessionalId({
                    idProfessional: idProfessional,
                  });

                  results = results.sort((pat1, pat2) =>
                    pat1.surname.localeCompare(pat2.surname)
                  );
                  setDevicesPatient(results);
                  setDevicesPatientUpdate(results);

                  setPatientsUpdated(true);
                }}
                options={patients}
                getOptionLabel={(option) =>
                  psTranslate(`${option.givenName} ${option.familyName}`)
                }
                getOptionSelected={(option, value) => option?.id === value?.id}
              />
            )}
          </SpTableCell>
        </SpTableRow>
      </>
    );
  };

  return (
    <>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={4}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.push("/mylab")}
                style={{
                  width: 30,
                  color: theme.colors.primary.lightBlue,
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.backButtonList + " / "}{" "}
                <span style={{ color: theme.colors.primary.white }}>
                  {labels.mylab.gpsDevices.header.title}
                </span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
          <Grid
            item
            xs={8}
            style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
          >
            <SpButton
              style={{ marginLeft: "2%" }}
              buttonType="accept"
              text={labels.mylab.tempDetail.toolbar.sync}
              onClick={() => syncPatients()}
              variant="h1"
            />
            {/* <SpButton
              disabled={true}
              style={{ marginLeft: "2%" }}
              buttonType="accept"
              text={labels.mylab.tempDetail.toolbar.add}
              variant="h1"
            /> */}
            <SpSearch
              onChange={(e) => {
                filterPatients(e);
              }}
              value={searchString}
              style={{
                marginLeft: "2%",
                marginRight: 0,
              }}
            />
          </Grid>
        </Grid>

        {/** DEVICES PATIENT TABLE **/}
        <Grid item xs={12} style={{ marginTop: "0.2em" }}>
          {devicesPatient && devicesPatient.length === 0 && (
            <SpText variant="h1PageTitle">
              {labels.mylab.gpsDevices.account.empty}
            </SpText>
          )}
          {devicesPatient && devicesPatient.length > 0 && (
            <StyledTableColumn>{table}</StyledTableColumn>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default withSnackbar(MyLabGpsDevicesPatient);
